@import "minima";

$color-crimson: #7b2328;
$color-poppy: #e63a3d;
$color-bare: #e9b8a7;

a {
  color: $color-poppy !important;
  &:hover {
    color: $color-crimson !important;
  }
  &:visited {
    color: $color-poppy !important;
  }
}

.post-title {
  color: $color-poppy;
}